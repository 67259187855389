var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{attrs:{"id":"footer","color":_vm.footerStyle?'var(--primary)':'transparent',"fixed":""}},[_c('v-row',{staticClass:"align",attrs:{"no-gutters":""}},[(!_vm.footerStyle)?_c('div',{staticClass:"marginaleft center"},_vm._l((_vm.dataSocial),function(item,i){return _c('v-btn',{key:i,attrs:{"icon":"","href":item.url,"target":"_blank"}},[_c('img',{staticStyle:{"--w":"1.5625em"},attrs:{"id":"social","src":require(`@/assets/icons/${item.icon}.svg`),"alt":`${item.icon} icons`}})])}),1):_c('section',{staticClass:"fill_w space gap2"},[_c('blockquote',{staticClass:"center gap1"},[_c('img',{staticStyle:{"--w":"3.06625em"},attrs:{"id":"track","src":_vm.track.img || require('@/assets/miscellaneous/track-white.png'),"alt":"track image"}}),_c('div',{staticClass:"divcol"},[_c('h6',{staticClass:"p",staticStyle:{"font-size":"clamp(1em, 1.4vw, 1.4em)"}},[_vm._v(_vm._s(_vm.track.name || "-"))]),_c('span',{staticClass:"font2",staticStyle:{"font-size":"clamp(1em, 1.2vw, 1.2em)"}},[_vm._v(_vm._s(_vm.visibleText || "-"))])])]),_c('aside',{staticClass:"acenter gap1"},[_vm._l((_vm.dataActionsLeft),function(item,i){return _c('v-btn',{key:i,class:{ 'activeBtn': _vm.isPressedLeft },staticStyle:{"--p":"clamp(1.2em ,1.5vw, 1.5em)"},attrs:{"icon":"","disabled":_vm.disabledTrack && ((item.key === 'previous' || item.key === 'next') || (item.key === 'shuffle' || item.key === 'repeat'))},on:{"click":_vm.btnLeft}},[_c('img',{style:(`transform: ${item.key=='next'?'rotate(180deg)':null}
            ${item.key=='play'?item.icon=='play-simple'?'translateX(2px)':'translateX(0)':null};
            --w: clamp(1.46em - .4em, 1.4vw, 1.46em)`),attrs:{"src":require(`@/assets/icons/${item.key=='play'?_vm.player.play?'pause-simple':'play-simple':item.icon}.svg`),"alt":`${item.key} icon`}})])}),_vm._l((_vm.dataActions),function(item,i){return _c('v-btn',{key:i,class:{eliminarmobile: item.deleteMobile},staticStyle:{"--p":"clamp(1.2em ,1.5vw, 1.5em)"},attrs:{"icon":"","disabled":_vm.disabledTrack && ((item.key === 'previous' || item.key === 'next') || (item.key === 'shuffle' || item.key === 'repeat'))},on:{"click":function($event){return _vm.clickPlayer(item)}}},[_c('img',{style:(`transform: ${item.key=='next'?'rotate(180deg)':null}
            ${item.key=='play'?item.icon=='play-simple'?'translateX(2px)':'translateX(0)':null};
            --w: clamp(1.46em - .4em, 1.4vw, 1.46em)`),attrs:{"src":require(`@/assets/icons/${item.key=='play'?_vm.player.play?'pause-simple':'play-simple':item.icon}.svg`),"alt":`${item.key} icon`}})])}),_vm._l((_vm.dataActionsRight),function(item,i){return _c('v-btn',{key:i,class:{ 'activeBtn': _vm.isPressedRight },staticStyle:{"--p":"clamp(1.2em ,1.5vw, 1.5em)"},attrs:{"icon":"","disabled":_vm.disabledTrack && ((item.key === 'previous' || item.key === 'next') || (item.key === 'shuffle' || item.key === 'repeat'))},on:{"click":_vm.btnRight}},[_c('img',{style:(`transform: ${item.key=='next'?'rotate(180deg)':null}
            ${item.key=='play'?item.icon=='play-simple'?'translateX(2px)':'translateX(0)':null};
            --w: clamp(1.46em - .4em, 1.4vw, 1.46em)`),attrs:{"src":require(`@/assets/icons/${item.key=='play'?_vm.player.play?'pause-simple':'play-simple':item.icon}.svg`),"alt":`${item.key} icon`}})])})],2),(_vm.timeTrack)?_c('aside',{staticClass:"center gap1 font2 eliminarmobile",staticStyle:{"width":"min(100%,18.6875em)"}},[_c('span',[_vm._v(_vm._s(_vm.getConvertTime(_vm.sliderValue)))]),_c('v-slider',{attrs:{"color":"#000000","min":0,"max":_vm.timeTrack,"hide-details":"","track-color":"hsl(0, 0%, 0%, .22)"},on:{"change":function($event){return _vm.changeTimeSlider()},"input":function($event){return _vm.inputTimeSlider()}},model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}}),_c('span',[_vm._v(_vm._s(_vm.getConvertTime(_vm.timeTrack)))])],1):_vm._e(),_c('aside',{staticClass:"center gap1",staticStyle:{"width":"min(100%,6.875em)"}},[_c('img',{staticStyle:{"--w":"clamp(1.6875em - .4em, 1.6vw, 1.6875em)"},attrs:{"src":require("@/assets/icons/sound.svg"),"alt":"sound"}}),_c('v-slider',{staticClass:"font2",attrs:{"color":"#000000","min":0,"max":100,"thumb-label":false,"hide-details":"","track-color":"hsl(0, 0%, 0%, .22)"},on:{"input":function($event){return _vm.changeSound()}},model:{value:(_vm.soundValue),callback:function ($$v) {_vm.soundValue=$$v},expression:"soundValue"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }